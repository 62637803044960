import { toFormValidator } from '@vee-validate/zod'
import * as zod from 'zod'

export const validationSchema = toFormValidator(
    zod.object({
        streetAddress: zod.string(),
        zipCode1: zod.string().length(5, 'Should contain 5 characters'),
        zipCode2: zod.string().length(5, 'Should contain 5 characters'),
        apn: zod.string()
    })
        .partial()
        .superRefine((data, ctx) => {
            if (!data.apn || !data.zipCode1) {
                if (data.streetAddress || data.zipCode2) {
                    if (!data.streetAddress) {
                        ctx.addIssue({
                            code: 'custom',
                            path: ['streetAddress'],
                            message: 'Required'
                        })
                    }
                    if (!data.zipCode2) {
                        return ctx.addIssue({
                            code: 'custom',
                            path: ['zipCode2'],
                            message: 'Required'
                        })
                    }
                } else {
                    if (!data.apn) {
                        ctx.addIssue({
                            code: 'custom',
                            path: ['apn'],
                            message: 'Required'
                        })
                    }
                    if (!data.zipCode1) {
                        ctx.addIssue({
                            code: 'custom',
                            path: ['zipCode1'],
                            message: 'Required'
                        })
                    }
                }
            }
        })
)
